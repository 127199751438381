<template>
  <v-container
    fluid
    class="pa-0 ma-0"
  >
    <title-block title="主席团" />
    <title-caption />
    <lecturer-block :size="220" :items="chairmans" />
    <title-block title="会议委员" />
    <title-caption />
    <lecturer-block :items="committees" />
    <title-block v-if="false" title="会议嘉宾" />
    <title-caption v-if="false" />
    <lecturer-block v-if="false" :items="lecturers" />
    <div class="d-flex align-center justify-center mt-8 mt-sm-16">
      <v-btn
        color="#01BDCB"
        class="white--text"
        width="270"
        @click="$router.push('/lecturer')"
      >
        <span class="text-subtitle-1">查看更多</span>
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import TitleBlock from '@/views/components/TitleBlock'
import TitleCaption from '@/views/components/TitleCaption'
import LecturerBlock from '@/views/components/LecturerBlock'
import Datas from '@/mixins/Datas.js'
export default {
  name: 'IndexLecturer',
  mixins: [ Datas],
  components: {
    TitleBlock,
    TitleCaption,
    LecturerBlock
  }
}
</script>
