<template>
  <v-container
    id="banner"
    fluid
    class="pa-0 ma-0"
  >
    <v-row
      v-if="is_mobile"
      no-gutters
      class="pt-4 pt-sm-16"
    >
      <v-col cols="12">
        <v-img
          width="100%"
          src="/images/numbers-m.jpg"
          class="overflow-y-hidden"
        >
          <div class="mx-auto d-flex white--text fill-height flex-wrap justify-center align-center numbers-bg" :style="maxWidth">
            <v-row no-gutters class="mx-auto flex-wrap">
              <v-col class="col-6 col-sm-3 mb-8 d-flex flex-column justify-space-between align-center align-self-stretch">
                <div class="text-h2 font-weight-bold d-flex justify-center align-center cs-move-box">
                  <div class="cs-five cs-move-counter">{{ five }}</div>
                </div>
                <div class="d-flex align-center">
                  <v-img
                    width="15"
                    height="15"
                    :src="require('@/assets/focus.svg')"
                    class="flex-grow-0"
                  />
                  <div class="pl-1">大主题板块聚焦行业热点</div>
                </div>
              </v-col>
              <v-col class="col-6 col-sm-3 mb-8 d-flex flex-column justify-space-between align-center align-self-stretch">
                <div class="text-h2 font-weight-bold d-flex justify-center align-center cs-move-box">
                  <div class="cs-thirty cs-move-counter">{{ thirty }}</div>
                  <div class="mt-n2">+</div>
                </div>
                <div class="d-flex align-center">
                  <v-img
                    width="15"
                    height="15"
                    :src="require('@/assets/lecturer.svg')"
                    class="flex-grow-0"
                  />
                  <div class="pl-1">位行业专家技术大咖</div>
                </div>
              </v-col>
              <v-col class="col-6 col-sm-3 d-flex flex-column justify-space-between align-center align-self-stretch">
                <div class="text-h2 font-weight-bold d-flex justify-center align-center cs-move-box">
                  <div class="cs-handred cs-move-counter">{{ handred }}</div>
                  <div class="mt-n2">+</div>
                </div>
                <div class="d-flex align-center">
                  <v-img
                    width="15"
                    height="15"
                    :src="require('@/assets/medias.svg')"
                    class="flex-grow-0"
                  />
                  <div class="pl-1">企业社区媒体</div>
                </div>
              </v-col>
              <v-col class="col-6 col-sm-3 d-flex flex-column justify-space-between align-center align-self-stretch">
                <div class="text-h2 font-weight-bold d-flex justify-center align-center cs-move-box">
                  <div class="cs-thousand cs-move-counter">{{ thousand }}</div>
                  <div class="mt-n2">+</div>
                </div>
                <div class="d-flex align-center">
                  <v-img
                    width="13"
                    height="13"
                    :src="require('@/assets/visitors.svg')"
                    class="flex-grow-0"
                  />
                  <div class="pl-1">线上观众</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-img>
      </v-col>
    </v-row>
    <v-row
      v-else
      no-gutters
      class="pt-4 pt-sm-16"
    >
      <v-col cols="12">
        <v-img
          width="100%"
          src="/images/numbers-pc.jpg"
          class="overflow-y-hidden"
        >
          <div class="d-flex white--text fill-height flex-wrap justify-center align-center numbers-bg">
            <div class="d-flex flex-wrap justify-center justify-sm-space-between align-center" :style="maxWidth">
              <div class="d-flex flex-column justify-space-between align-center align-self-stretch">
                <div class="text-h2 font-weight-bold d-flex justify-center align-center cs-move-box">
                  <div class="cs-five cs-move-counter">{{ five }}</div>
                </div>
                <div class="d-flex align-center">
                  <v-img
                    width="15"
                    height="15"
                    :src="require('@/assets/focus.svg')"
                    class="flex-grow-0"
                  />
                  <div class="pl-1">大主题板块聚焦行业热点</div>
                </div>
              </div>
              <div class="d-flex flex-column justify-space-between align-center align-self-stretch">
                <div class="text-h2 font-weight-bold d-flex justify-center align-center cs-move-box">
                  <div class="cs-thirty cs-move-counter">{{ thirty }}</div>
                  <div class="mt-n2">+</div>
                </div>
                <div class="d-flex align-center">
                  <v-img
                    width="15"
                    height="15"
                    :src="require('@/assets/lecturer.svg')"
                    class="flex-grow-0"
                  />
                  <div class="pl-1">位行业专家技术大咖</div>
                </div>
              </div>
              <div class="d-flex flex-column justify-space-between align-center align-self-stretch">
                <div class="text-h2 font-weight-bold d-flex justify-center align-center cs-move-box">
                  <div class="cs-handred cs-move-counter">{{ handred }}</div>
                  <div class="mt-n2">+</div>
                </div>
                <div class="d-flex align-center">
                  <v-img
                    width="15"
                    height="15"
                    :src="require('@/assets/medias.svg')"
                    class="flex-grow-0"
                  />
                  <div class="pl-1">企业社区媒体</div>
                </div>
              </div>
              <div class="d-flex flex-column justify-space-between align-center align-self-stretch">
                <div class="text-h2 font-weight-bold d-flex justify-center align-center cs-move-box">
                  <div class="cs-thousand cs-move-counter">{{ thousand }}</div>
                  <div class="mt-n2">+</div>
                </div>
                <div class="d-flex align-center">
                  <v-img
                    width="13"
                    height="13"
                    :src="require('@/assets/visitors.svg')"
                    class="flex-grow-0"
                  />
                  <div class="pl-1">线上观众</div>
                </div>
              </div>
            </div>
          </div>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Tools from '@/mixins/Tools.js'
export default {
  name: 'IndexNumber',
  mixins: [Tools],
  data() {
    return {
      five: 0,
      thirty: 0,
      handred: 0,
      thousand: 0,
      five_timer: null,
      thirty_timer: null,
      handred_timer: null,
      thousand_timer: null
    }
  },
  mounted() {
    this.handleFive()
    this.handleThirty()
    this.handleHandred()
    this.handleThousand()
  },
  methods: {
    handleFive(step=1, max=5) {
      this.five_timer = setTimeout(() => {
        let tmp = this.five + step
        if (tmp > max) {
          tmp = max
          clearTimeout(this.five_timer)
        }
        this.five = tmp
        if (tmp < max) {
          this.handleFive(step, max)
        }
      }, 100)
    },
    handleThirty(step=1, max=35) {
      this.thirty_timer = setTimeout(() => {
        let tmp = this.thirty + step
        if (tmp > max) {
          tmp = max
          clearTimeout(this.thirty_timer)
        }
        this.thirty = tmp
        if (tmp < max) {
          this.handleThirty(step, max)
        }
      }, 100)
    },
    handleHandred(step=2, max=100) {
      this.handred_timer = setTimeout(() => {
        let tmp = this.handred + step
        if (tmp > max) {
          tmp = max
          clearTimeout(this.handred_timer)
        }
        this.handred = tmp
        if (tmp < max) {
          this.handleHandred(step, max)
        }
      }, 100)
    },
    handleThousand(step=31, max=10000) {
      this.thousand_timer = setTimeout(() => {
        let tmp = this.thousand + step
        if (tmp > max) {
          tmp = max
          clearTimeout(this.thousand_timer)
        }
        this.thousand = tmp
        if (tmp < max) {
          this.handleThousand(step, max)
        }
      }, 20)
    }
  }
}
</script>
<style lang="sass" scoped>
.numbers-bg
  background-color: rgba(0,0,0,0.6)
.cs-move-box
  min-width: 114px
@property --num
  syntax: "<integer>"
  initial-value: 0
  inherits: false
.cs-move-counter::after-
  content: counter(num)
.cs-thousand-
  animation: counter_thousand 9s 1 alternate ease-in-out forwards
  counter-reset: num var(--num)
.cs-handred-
  animation: counter_handred 5s 1 alternate ease-in-out forwards
  counter-reset: num var(--num)
.cs-thirty-
  animation: counter_thirty 2.5s 1 alternate ease-in-out forwards
  counter-reset: num var(--num)
.cs-five-
  animation: counter_five 1s 1 alternate ease-in-out forwards
  counter-reset: num var(--num)
@keyframes counter_thousand
  from
    --num: 0
  to
    --num: 10000
@keyframes counter_handred
  from
    --num: 0
  to
    --num: 100
@keyframes counter_thirty 
  from
    --num: 0
  to
    --num: 30
@keyframes counter_five 
  from
    --num: 0
  to
    --num: 5
</style>