<template>
  <v-container
    fluid
    class="ma-0 pa-0"
  >
    <index-carousel :style="marginTop" />
    <div class="mx-auto my-0 py-0 px-3 px-sm-0" :style="maxWidth">
      <index-intro />
    </div>
    <index-numbers />
    <div class="mx-auto my-0 py-0 px-3 px-sm-0" :style="maxWidth">
      <index-agenda />
      <index-lecturer />
    </div>
    <index-map />
    <index-partner />
    <div class="mx-auto my-0 py-0 px-3 px-sm-0 pb-10 pb-sm-16" :style="maxWidth">
      <index-problems />
    </div>
  </v-container>
</template>
<script>
import IndexCarousel from './components/IndexCarousel.vue'
import IndexIntro from './components/IndexIntro.vue'
import IndexNumbers from './components/IndexNumbers.vue'
import IndexAgenda from './components/IndexAgenda.vue'
import IndexLecturer from './components/IndexLecturer.vue'
import IndexMap from './components/IndexMap.vue'
import IndexProblems from './components/IndexProblems.vue'
import IndexPartner from './components/IndexPartner.vue'
import Tools from '@/mixins/Tools.js'
export default {
  name: 'Home',
  mixins: [Tools],
  components: {
    IndexCarousel,
    IndexIntro,
    IndexNumbers,
    IndexAgenda,
    IndexLecturer,
    IndexMap,
    IndexProblems,
    IndexPartner
  }
}
</script>