<template>
    <baidu-map
      :style="mapStyle"
      ak="6YiS8IS4lGtPSj1i4cWSp8LPPAlQmja8"
      center="上海市普陀区长寿路700号古井假日酒店"
      :zoom="zoom"
      :scroll-wheel-zoom="true"
      @tilesloaded="syncCenterAndZoom"
    >
      <bm-view :style="mapStyle" />
      <bm-marker :position="{lng: 121.439829, lat: 31.244339}" :dragging="true" @dragend="handleMarkerPosition" />
    </baidu-map>
</template>
<script>
import { BaiduMap, BmView, BmMarker } from 'vue-baidu-map'
export default {
  name: 'MyBaiduMap',
  components: {
    BaiduMap,
    BmView,
    BmMarker
  },
  props: {
    height: {
      type: String,
      default: '286px'
    }
  },
  data() {
    return {
      zoom: 19
    }
  },
  computed: {
    mapStyle() {
      return {
        width: '100%',
        height: this.height
      }
    }
  },
  methods: {
    syncCenterAndZoom(e) {
      this.center = e.target.getCenter()
      this.zoom = e.target.getZoom()
    },
    handleMarkerPosition(e) {
      this.center = e.point
    },
  }
}
</script>
