<template>
  <v-container
    fluid
    class="pa-0 ma-0"
  >
    <title-block title="背景介绍" />
    <v-row no-gutters>
      <v-col cols="12">
        <p class="intro-text grey--text text--darken-4 text-body-1">Hadoop是Apache基金会旗下最知名的基础架构开源项目之一。自2006年诞生以来，是海量数据存储、处理最为重要的基础组件，并由此形成了非常丰富的技术生态。</p>
        <p class="intro-text grey--text text--darken-4 text-body-1" v-if="false"></p>
        <p class="intro-text grey--text text--darken-4 text-body-1 mb-0">2022年9月24日，示说继续携手最活跃的大数据基础架构开发者们，秉持开放、公益、合作的心态，举办第四届中国 Apache Hadoop Meetup，希望在开源技术发生深刻变革以及产业生态再造的当下，为开发者集思广益、寻求创新变革提供思想交锋的平台，打造技术交流的盛宴。</p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TitleBlock from '@/views/components/TitleBlock.vue'
export default {
  name: 'IndexIntro',
  components: {
    TitleBlock
  }
}
</script>
<style lang="sass" scoped>
.intro-text
  line-height: 40px
  text-indent: 36px
</style>