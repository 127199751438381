<template>
  <div class="text-caption pa-0">{{ title }}</div>
</template>
<script>
export default {
  name: 'TitleCaption',
  props: {
    title: {
      type: String,
      default: '（按姓氏首字母排序）'
    }
  }
}
</script>
