<template>
  <v-row class="pt-2 pt-sm-6">
    <template v-if="size !== 166">
      <v-col
        v-for="(item, index) of items"
        :key="`lecturer-${item.branch}-${index}`"
        class="col-6 col-sm-3 align-self-stretch"
      >
        <lecturer-card :size="!is_mobile ? size : 166" :order="index+1" :item="item" />
      </v-col>
    </template>
    <template v-else>
      <v-col
        v-for="(item, index) of items"
        :key="`lecturer-${item.branch}-${index}`"
        class="col-6 col-sm-2 align-self-stretch"
      >
        <lecturer-card :size="size" :order="index+1" :item="item" />
      </v-col>
    </template>
  </v-row>
</template>
<script>
import LecturerCard from './LecturerCard.vue'
import Tools from '@/mixins/Tools.js'
export default {
  name: 'LecturerBlock',
  mixins: [Tools],
  components: {
    LecturerCard
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    size: {
      type: Number,
      default: 166
    }
  }
}
</script>
