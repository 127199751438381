<template>
  <v-container
    fluid
    class="pa-0 ma-0 pb-10 pb-sm-16"
  >
    <div class="mx-auto my-0 py-0 px-3 px-sm-0" :style="maxWidth">
      <title-block title="参会指南" />
    </div>
    <div v-if="is_mobile" class="d-block pt-4">
      <baidu-map height="180px" />
    </div>
    <div v-else class="mx-auto my-0 py-0 pt-sm-6" :style="maxWidth">
      <baidu-map height="286px"/>
    </div>
    <div class="mx-auto my-0 py-0 px-3 " :style="maxWidth">
      <div class="d-flex align-center justify-center py-3 py-sm-6">
        <v-img
          max-width="20"
          width="20"
          height="20"
          :src="require('@/assets/map.svg')"
          class="flx-grow-0 mb-4"
        />
        <div class="pl-1 text-body-2 text-sm-body-1 mb-4">长寿路700号上海古井假日酒店4楼，靠近7号线和13号线</div>
      </div>
      <div class="text-body-2 text-sm-body-1 mb-4 mb-sm-6 font-weight-bold">参会防疫提醒</div>
      <div class="text-body-2 text-sm-body-1 mb-4 mb-sm-6">① 参加线下会议的同学，请持72小时内核酸检测报告，为了您和他人的防疫安全，并全程佩戴口罩。</div>
      <div class="text-body-2 text-sm-body-1 mb-4 mb-sm-6">② 到达现场后，需配合工作人员体温检测并扫健康码，无异常后方可入内参会。</div>
      <div class="text-body-2 text-sm-body-1">③ 到达4楼后请及时入座，避免人员聚集，社交请保持1米左右安全距离。</div>
    </div>
  </v-container>
</template>
<script>
import TitleBlock from '@/views/components/TitleBlock.vue'
import BaiduMap from '@/views/components/BaiduMap.vue'
import Tools from '@/mixins/Tools.js'
export default {
  name: 'IndexMap',
  components: {
    TitleBlock,
    BaiduMap
  },
  mixins: [Tools]
}
</script>
